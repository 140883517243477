@import "../../css/_brand_colors.scss";
@import "../../css/globals";

.document-preview {
  margin: 0 0 0 5px;
  padding: 0;
}

.document-preview-card-main {
  height: calc(100% - 45px);
  overflow: auto;
  background: $brand-light-grey;
}

// Make sure the document preview doesn't go over the edge of the screen
@media (min-width: 600px) {
  .document-preview-card-main {
    max-width: calc(100vw - 174px);
  }
  :global(#quickPanelContainer) .document-preview-card-main {
    max-width: 100%;
  }
}

.document-preview-page-container {
  text-align: center;
}

.document-preview-page {
  &:first-of-type {
    // Styles for the first element
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  // All the other pages will have 15px on top and bottom
  display: inline-block;
  margin: 15px auto;
}

.document-preview-page-loading {
  background-color: $brand-white;
  border-left: 1px solid $brand-mild-grey;
  border-right: 1px solid $brand-white;
  height: 500px; // An approximate page height
  padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
}

.document-preview-control-bar {
  align-items: center;

  :global(.toolbar-divider) {
    height: 20px;
    margin-top: 11px;
    margin-bottom: 11px;
    border-left: 1px solid $brand-mild-grey;
    border-right: 1px solid $brand-white;
    padding: 0;
  }

  :global(.document-preview-download) {
    flex-wrap: nowrap;
    max-width: 40%;
    min-width: 40%;
    margin: 0 5px;

    a {
      display: inline-flex;
      justify-content: flex-start;
      flex-direction: row;
      width: auto;
      vertical-align: top;
      min-width: 0;
      max-width: fit-content;
    }

    .btn-group {
      //border: solid 1px #3aa266;
    }

    .file-name {
      white-space: nowrap;
      overflow: hidden;
      width: auto;
      text-overflow: ellipsis;
      max-width: fit-content;
    }

    .file-extension {
      flex-grow: 0;
      flex-shrink: 0;
      max-width: fit-content;
      min-width: fit-content;
    }

    .download-icon {
      flex-grow: 0;
      flex-shrink: 0;
      min-width: fit-content;
      max-width: fit-content;
      vertical-align: center;
    }
  }

  :global(.document-preview-pager) {
    width: 20%;
    min-width: 90px;
    margin: 0 5px;

    .input-group-text {
      background: transparent;
      border: none;
      max-width: 48px;
    }
  }

  :global(.document-preview-zoom) {
    max-width: 40%;
    min-width: 40%;
    margin: 0 5px;

    .btn {
      color: $brand-medium-grey;
    }
  }
}
