/* This SCSS file is responsible for all of the CSS on our headers, titles, bread crumbs, company logos, etc at the top of the page.
 */
@import "brand_colors";
@import "globals";

#bodyDiv {
  position: absolute;
  min-height: 100%;
  width: 100%;
  background-color: $brand-light-grey;
}

.loading-div {
  position: fixed;
  width: 100%;
  height: 120px;
  z-index: 3001; // See QV-413 So that the spinner appears above the change password dialog.
  top: 50%;
  margin-top: -100px;
}

.body-loading {
  cursor: progress !important;
}

.translation-loading-dim-wrapper,
.loading-dim-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background: $brand-white;
  z-index: 3000; // See QV-413 So that the spinner appears above the change password dialog.
  opacity: 1;
}

#loadingImg {
  margin-left: calc(50% - 61px);
  margin-bottom: 40px;
}

#loadingText {
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}

.full-screen-display,
.full-screen-display #bodyDiv {
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.full-screen-display #mentaAddPage #bodyDiv {
  overflow: auto;
}

.full-screen-container {
  display: flex;
  flex-direction: column;
  flex: 1; // fills all the space left
  overflow: hidden;
}

.cookies-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 50px;
  background: $brand-mild-grey;
  color: #434343;
  z-index: 101;
}

.cookies-bar-got-it-button {
  float: right;
  margin-top: 10px;
  padding: 5px 20px 5px 20px;
}

.trial-bar {
  background: $brand-black;
  color: $brand-white;
  display: inline-table;
  min-height: $trial-bar-height;
  width: 100%;
}

.cookies-bar-notification,
.trial-trial-text {
  display: inline-block;
  margin-top: 15px;
}

#trialDaysLeft {
  background: $brand-white;
  color: $brand-black;
  padding: 5px;
  border-radius: 4px;
  margin-left: 3px;
}

.trial-bar-subscribe-now-button {
  float: right;
  margin-top: 6px;
  background: $brand-highlight-color;
}

.trial-X,
.trial-X:hover {
  color: $brand-white;
  float: right;
  font-size: calculateRem(18px);
  font-weight: bold;
  margin-top: 12px;
  margin-left: 30px;
}

.tab-nav-bar {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.tab-nav-bar a,
.tab-nav-bar a:hover {
  color: inherit;
  font-size: calculateRem(16px);
}

.tab-nav-bar a:hover {
  text-decoration: underline;
}

.tab-nav-bar-item {
  @include navBarItem();

  display: inline-block;
  font-size: calculateRem(18px);
  margin-right: 15px;
  white-space: nowrap;

  &:hover {
    background-color: initial;
  }
}

.tab-nav-bar-item-selected {
  @include navBarSelected();

  display: inline-block;
  font-size: calculateRem(18px);
}

.tab-nav-bar-tab-content-hidden {
  display: none;
}

.tab-nav-bar-icon {
  margin-left: 10px;
  margin-right: 5px;

  .svg-inline--fa {
    margin-left: 0;
  }
}

.tab-nav-bar-error-icon-red {
  color: $error-color;
}

.tab-nav-bar-warning-icon-yellow {
  color: $warning-color;
}

.tab-nav-bar-warning-icon-blue {
  color: $info-color;
}

.info-icon-blue {
  color: $brand-dark-blue;
}

@media print {
  .tab-nav-bar-item-selected {
    background-color: $brand-white !important;
  }
}

@media print {
  .trial-bar {
    display: none;
  }

  .cookies-bar {
    display: none;
  }
}

.trial-expired-text {
  margin-top: 50px;
  margin-bottom: 30px;
}

.trial-expired-contact-sales {
  display: block;
  margin-top: 30px;
}

.company-header-feedback {
  color: $brand-black;
  align-self: center;
  padding: 0 2em;
  font-size: 1.3em;
}

.company-header-container-flex,
.company-header-container-minified {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .company-header-feedback {
    padding: 0 1em;
  }
}

.company-header-container-minified {
  margin-left: 5px;
}

@media print {
  .company-header-container-flex,
  .company-header-container-minified {
    align-items: start;
  }

  .company-header-container-minified button#processDropDownButton {
    margin-left: calculateRem(28px);
  }
}

.company-header-container-minified #processDropDownButton {
  font-weight: 400;
  margin-left: calculateRem(5px);
}

.company-header {
  min-height: $company-header-height;
  width: 100%;
  background-color: $brand-white;
  color: $brand-black;
  display: inline-table;
  padding-top: 5px;
  padding-bottom: 5px;
}

.company-header-party-mode {
  animation: font-animation 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes font-animation {
  0% {
    text-shadow: -6px 4px 0 red;
  }

  10% {
    text-shadow: 4px -6px 0 green;
  }

  20% {
    text-shadow: -9px 4px 0 blue;
  }

  30% {
    text-shadow: 4px -6px 0 yellow;
  }

  40% {
    text-shadow: -8px 4px 0 orange;
  }

  50% {
    text-shadow: 4px 5px 0 purple;
  }

  60% {
    text-shadow: -6px 4px 0 brown;
  }

  70% {
    text-shadow: 4px 7px 0 pink;
  }

  80% {
    text-shadow: -9px -4px 0 lime;
  }

  90% {
    text-shadow: 4px -6px 0 cyan;
  }

  100% {
    text-shadow: -9px 4px 0 teal;
  }
}

.company-header-minified {
  height: auto;
  width: 100%;
  background-color: $brand-white;
  color: $brand-black;
  display: inline-table;
  padding: 5px 0 3px;
}

.company-header-project {
  font-weight: bold;
  font-size: calculateRem(14px);
  font-family: "Open Sans", sans-serif;
}

@media print {
  .company-header-project {
    margin-top: 24px;
  }
}

//noinspection Stylelint
.company-header-project a {
  color: $brand-primary-color !important;
}

.company-header-minified-title {
  font-weight: bold;
  font-size: calculateRem(14px);
  font-family: "Open Sans", sans-serif;
  color: $brand-black;
  line-height: calculateRem(19px);
}

.company-header-version-label {
  color: $brand-dark-grey;
  font-weight: bold;
}

@media print {
  .company-header-minified-title {
    margin-top: 24px;
  }
}

@media print {
  .company-header-separator {
    display: none;
  }
}

.company-header-separator {
  font-size: 1.28em;
  margin: 0 calculateRem(7px);
}

.company-header-title {
  color: $brand-black;
  font-size: 1.57em;
  margin-top: 3px;
}

.logo-container {
  display: inline-block;
  margin-top: 5px;
}

.logo-container-minified {
  display: inline-block;
}

@media print {
  .logo-container-minified {
    margin-top: 15px;
  }
}

.logo-icon {
  float: left;
  margin-right: 10px;
}

.qbd {
  color: $brand-dark-blue;
  font-family: "Roboto", sans-serif;
  font-weight: 600; // Use this instead of "Roboto Black" for the font-family because only this works on Firefox.
}

.vision {
  color: $brand-highlight-color;
  font-family: "Roboto", sans-serif;
  font-weight: 600; // Use this instead of "Roboto Black" for the font-family because only this works on Firefox.
}

#userNameDiv {
  max-width: 40px;
}

@media screen {
  #logoVersionLabel,
  #printInfo {
    display: none;
  }
}

.user-settings-div {
  display: block;
  flex-grow: 1;
  z-index: 2200;
}

/* stylelint-disable no-descending-specificity */
.header-breadcrumb {
  font-weight: 600;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: #1f46a1 !important;
  }
}

/* stylelint-enable no-descending-specificity */

@media print {
  #companyNameDiv,
  #userNameDiv {
    display: none;
  }

  #printInfo {
    border: 1px solid $brand-light-grey;
    padding: 10px;
    margin: 10px 10px 10px auto; // essentially makes it float right with the flex layout
    display: table;
  }

  .print-row {
    padding-bottom: 5px;
    display: table-row;
  }

  .print-label {
    display: table-cell;
    font-weight: bold;
    padding-right: 10px;
    vertical-align: top;
    white-space: nowrap;
  }

  .print-data {
    display: table-cell;
    max-width: 200px;
    vertical-align: top;
  }
}

.page-title-bar {
  height: $page-title-bar-height;
  width: 100%;
  background-color: $brand-dark-blue;
  color: $brand-white;
  display: table;
  padding-top: 10px;
  padding-bottom: 5px;
}

.page-title-bar-party-mode {
  animation: background-animation 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@media print {
  .page-title-bar {
    background-color: $brand-white;
    color: $brand-medium-grey;

    .page-title-bar-title {
      color: $brand-medium-grey;
    }
  }
}

@keyframes background-animation {
  0% {
    background: red;
  }

  10% {
    background: green;
  }

  20% {
    background: blue;
  }

  30% {
    background: yellow;
  }

  40% {
    background: orange;
  }

  50% {
    background: purple;
  }

  60% {
    background: brown;
  }

  70% {
    background: pink;
  }

  80% {
    background: lime;
  }

  90% {
    background: cyan;
  }

  100% {
    background: teal;
  }
}

.page-title-bar-title {
  color: $brand-white;
  display: inline-block;
  font-size: 1.43em;
  font-weight: bold;
}

@media print {
  .page-title-bar-title-project {
    display: none;
  }
}

.page-title-bar-title-superscript {
  font-style: italic;
  margin-left: 5px;
  color: $brand-light-grey;
}

.page-title-bar-breadcrumbs {
  margin-right: 5px;
  margin-bottom: 5px;

  .page-title-bar-breadcrumbs-import {
    font-size: 0.7em; // back to 14px
  }
}

@media print {
  .page-title-bar-breadcrumbs a {
    text-decoration: none;
  }
}

.bulk-proposal-title-bar-breadcrumb,
.bulk-approval-title-bar-breadcrumb {
  font-size: 1em;
  margin-top: -15px;
}

//noinspection Stylelint
.page-title-bar-title a,
.page-title-bar-title a:hover {
  color: inherit;
}

.page-title-bar-archived-stamp {
  color: $brand-white;
  float: right;
  font-family: old-stamper, serif;
  font-size: calculateEm(14px);
  margin-left: 10px;
  margin-top: 7px;
}

.title-bar-primary,
.quick-panel-title-bar-primary {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.static-title-bar-risk-label,
.page-title-bar-risk-label {
  color: $brand-dark-grey;
  float: right;
  font-size: calculateEm(14px);
  margin-left: 15px;
}

.page-title-bar-process-name,
.static-title-bar-process-name {
  border: solid thin $brand-mild-grey;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  margin-left: 10px;
  padding: 2px 5px;
}

.list-table-risk-label-title-bar {
  margin-right: 5px !important;
}

.page-title-bar-status,
.page-title-bar-status-mini {
  color: $brand-white;
  float: right;
  font-family: old-stamper, serif;
  font-size: calculateEm(14px);
  margin-right: 15px;
}

@media (min-width: 490px) {
  .page-title-bar-status {
    font-size: calculateEm(24px);
  }

  .page-title-bar-status-mini {
    font-size: calculateEm(18px);
  }
}

@media (min-width: 768px) {
  .page-title-bar-status {
    font-size: calculateEm(40px);
    margin-right: -15px;
  }

  .page-title-bar-status-mini {
    font-size: calculateEm(22px);
  }
}

.nav-bar {
  background-color: $brand-dark-blue;
  color: $brand-white;
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 767px) {
  .nav-bar {
    .container {
      width: 100%;
    }
  }
}

.nav-bar-item {
  background-color: $brand-dark-blue;
  color: $brand-white;
  font-size: $nav-bar-item-font-size;
  height: $nav-bar-height;
  padding-top: calc(#{$nav-bar-item-border-top} + #{$nav-bar-item-padding-top});
  padding-bottom: $nav-bar-item-padding-bottom;
  text-align: center;
}

.nav-bar-item-selected {
  background-color: $brand-light-grey;
  border-top: solid $nav-bar-item-border-top $brand-highlight-color;
  padding-top: $nav-bar-item-padding-top;
  margin-top: 0;
  color: $brand-black;
}

@media (min-width: 768px) {
  .nav-bar-item-selected {
    margin-bottom: -3px;
  }
}

@media print {
  .nav-bar {
    display: none;
  }

  /*
  This next part isn't really necessary anymore, since we decided to not show the Nav Bar. But we might need it back
  for some reason in the future.
  */
  .nav-bar-item {
    background-color: $brand-light-grey !important;
    border-right: 1px solid $brand-mild-grey;
    border-bottom: 1px solid $brand-mild-grey;
  }

  .nav-bar-item-selected {
    background-color: $brand-white !important;
    border-bottom: 0;
  }
}

//noinspection Stylelint
.nav-bar-item a,
.nav-bar-item a:hover {
  color: inherit;
  display: inline-block;
  width: 80px;
}

@media (min-width: 768px) {
  // This is because "Process Explorer" wraps at this size of screen
  .nav-bar-item-selected {
    height: $nav-bar-height;
  }
}

@media (min-width: 768px) {
  .container-spacer {
    background-color: $brand-light-grey;
    height: 20px;
  }
}

@media print {
  .container-spacer {
    display: none;
  }
}

.nav-header {
  background-color: $brand-light-grey;
  color: $brand-black;
  padding-left: 30px;
}

.title-bar {
  background-color: $brand-light-grey;
  color: $brand-black;
  display: inline-table;
  min-height: 72px;
  margin-left: -20px;
  padding: 15px 5px;
  width: calc(100% + 40px);
}

@media (max-width: 767px) {
  .title-bar {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
  }

  .approval-container {
    display: table !important;
  }
}

.title-bar.stick-to-top {
  height: 90px;
}

.title-bar-title {
  float: left;
  font-weight: bold;
  color: $brand-black;
}

.title-bar-title.full-screen-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .btn-group {
    align-self: flex-end;
  }
}

.title-bar-primary-name {
  font-size: calculateEm(24px);
}

.quick-panel-title-bar-title-row {
  padding-left: 15px;
  padding-right: 15px;
}

.title-bar-breadcrumbs {
  font-size: calculateEm(14px);
}

.title-bar-status {
  float: right;
  font-family: old-stamper, serif;
  font-size: calculateEm(24px);
  display: none;
  margin-top: 16px;
}

.title-bar-bottom-border {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 15px;
  background-color: #0000;
  border-bottom: 1px solid $brand-medium-grey;
}

@media (min-width: 768px) {
  .title-bar-bottom-border {
    left: calc(-1 * (100vw - 100%) / 2);
    right: calc(-1 * (100vw - 100% - 8px) / 2);
  }
}

.stick-to-top {
  position: sticky !important;
  top: 0;
  z-index: 9; // The date picker on the risk tables is z-index 10, and gets covered up if this is any higher.

  .title-bar-status {
    display: block;
  }

  .title-bar-bottom-border {
    display: block;
  }
}

.stick-to-left {
  position: sticky !important;
  left: 0;
  z-index: 8;
}

@media print {
  .stick-to-top {
    position: static !important;
  }

  .stick-to-left {
    position: static !important;
  }
}

.header-banner {
  padding-top: $header-banner-padding-top;
  padding-bottom: $header-banner-padding-bottom;
}

.header-banner .col-sm-12 {
  background-color: inherit;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .header-banner .col-sm-12 {
    background-color: inherit;
    padding-left: 0;
    padding-right: 0;
  }
}

.header-banner h1 {
  font-weight: bold;
}

.error-bar {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 10px;
}

.login-error-bar {
  margin: 10px 0;
}

.error-details {
  padding: 20px;
}

#criticalErrorModal {
  z-index: 999999999 !important;

  .modal-body {
    overflow: auto;
    max-height: 60vh;
  }
}

a.error-bar-link {
  font-weight: bold;
  float: none !important;
}

.header-inline {
  display: inline-block;
}

.info-tooltip-icon {
  color: $brand-primary-color;
  cursor: pointer;
  font-size: calculateEm(14px);
  margin-left: 10px;

  h1 &,
  h2 &,
  h3 &,
  h4 &,
  h5 & {
    font-size: 14px; // Hardcode this font because the hX tag changes the default font size.
  }
}

.info-tooltip-type-container {
  height: 30px;
}

.info-tooltip-verbiage {
  float: right;
  margin-right: 10px;
  cursor: pointer;
}

.info-tooltip-explanation {
  float: right;
  margin-left: 10px;
  cursor: pointer;
}

.info-tooltip-type-selected {
  color: $brand-black;
  font-weight: bold;
}

.info-tooltip-type-selected:hover {
  color: inherit;
  cursor: inherit;
  text-decoration: none;
}

.info-tooltip-content-explanation {
  font-size: calculateEm(14px);
}

.info-tooltip-content-row {
  white-space: pre-wrap;
}

.info-tooltip-content-verbiage {
  font-size: calculateEm(14px);
}

.info-tooltip-footer {
  float: right;
  height: 30px;
  margin-top: 20px;
}

.company-header-name {
  font-size: 1.3em;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  max-height: 1.8em;
}

.company-header-name-minified {
  margin-right: 5px;
  font-size: 1.14em;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.user-settings-nav-list {
  border-radius: 4px !important;
  border: solid thin $brand-highlight-color;
  padding: 1px;
}

#companyHeader a {
  color: inherit;
}

#companyHeader .user-settings-nav-list {
  width: 253px;
  transform: translate(-215px, -12px) !important;
}

#companyHeader .user-settings-nav-list a {
  color: $brand-dark-grey;
  padding-right: 15px;
  padding-top: 15px;
}

.user-settings-nav-div.show {
  z-index: 1100;
}

.user-settings-username-div {
  display: flex;
  padding-bottom: 11px;
  margin-top: 70px;
  border-bottom: solid thin $hover-color;
}

.user-settings-nav-list-username {
  font-family: "Roboto", sans-serif;
  font-size: calculateEm(18px);
  font-weight: bold;
  margin-right: 10px;
}

.user-settings-user-icon {
  font-size: calculateEm(24px);
  color: #666;
  margin-left: 30px;
  margin-right: 10px;
}

.user-settings-username {
  align-self: center;
  margin-left: 5px;
}

.user-settings-nav-menu-icon {
  font-size: 1.3em;
}

.user-settings-nav-menu-separator {
  border-top: solid thin $hover-color;
  margin-top: 18px;
  padding-bottom: 20px;
}

.user-settings-nav-menu-item-margin-top {
  margin-top: 10px;
}

.user-settings-nav-menu-item-margin-bottom {
  margin-bottom: 15px;
}

.user-settings-nav-list .user-settings-nav-menu-copyright {
  font-size: 0.86em;
}

#companyHeader .user-settings-nav-list a.user-settings-nav-menu-copyright-link {
  color: $brand-primary-color;
}

.user-settings-nav-menu-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 64px;
  cursor: pointer;
}

.user-settings-nav-menu-item:hover {
  background-color: #ededed;
}

.user-settings-nav-close-icon {
  font-size: calculateEm(16px);

  /* Position info: */
  position: absolute;
  top: 0;
  right: 5px;
}

.editor-page-header-link-div {
  float: right;
  font-size: calculateRem(14px);
  padding: 0;
}

.editor-page-header-link {
  float: right;
}

.editor-page-header-text {
  padding: 0;
}

nav.header-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.company-header-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

@media print {
  .company-header-feedback,
  nav.header-nav {
    display: none;
  }
}

.page-frame-layout-error-bar {
  margin-top: 20px;
  margin-left: -15px;
}

@keyframes skeletonShimmer {
  from {
    background-position: 100% 0;
  }

  to {
    background-position: -100% 0;
  }
}

.skeleton {
  /* The loading CSS will appear over the most recent parent node with position relative. */
  position: relative;
  min-height: calculateRem(21px);

  &::after {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: skeletonShimmer;
    animation-timing-function: linear;
    background-image: linear-gradient(
      90deg,
      $brand-light-grey 0,
      rgba(#e6edf0, 0.95) 20%,
      $brand-light-grey 40%,
      $brand-light-grey 100%
    );
    background-size: 200% 100%;
    border-radius: 3px;
    content: "";
    display: block;
    z-index: 3001;

    /* stylelint-disable declaration-empty-line-before */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* stylelint-enable declaration-empty-line-before */
  }
}

button.skeleton::after {
  // Tone down the skeleton for buttons. They'll just be disabled.
  opacity: 0.7;
  border-top: 0;
}

$table-header-height: 40px;
$table-row-height: 64px;
$table-bottom-border-height: 2px;

.skeleton.skeleton-table {
  min-height: ($table-row-height * 2) + $table-header-height + $table-bottom-border-height;

  &::after {
    margin-top: $table-header-height;
    content: url("../images/skeleton/table-skeleton.png");
    height: $table-row-height * 2 - $table-bottom-border-height;
    width: 324px; // width of the table-skeleton.png image.
  }

  &.skeleton-table-batches {
    $batch-table-header-second-row-height: 25px;
    $batch-table-header-height: $table-header-height + $batch-table-header-second-row-height;

    &::after {
      margin-top: $batch-table-header-height;
    }
  }

  tbody tr:hover {
    background: $brand-white;
  }
}

.skeleton.skeleton-process-explorer::after {
  // This image will appear on top of the shimmering background. Use PNG transparency to show the shimmering through it.
  content: url("../images/skeleton/process-explorer-skeleton.png");
  height: 282px;
  margin-left: 60px;
  width: 390px;
}

.skeleton.skeleton-home-page::after {
  // This image will appear on top of the shimmering background. Use PNG transparency to show the shimmering through it.
  content: url("../images/skeleton/home-page-skeleton.png");
  height: 180px;
  width: 400px;
}

.rest-api-iframe {
  background-color: $brand-white;
  border: 0;
  height: calc(100vh - 65px);
  overflow: hidden;
  width: 100%;
}
