/**
 * This file is responsible for the css related to the quick edit panels
 */
@import "globals";
@import "brand_colors";

.quick-panel-outside-container {
  display: inline-block;
  overflow-x: auto;
  overflow-y: scroll;
  position: absolute;
  height: 100%;
  width: 100%;
}

.quick-panel-link-wrap {
  word-wrap: break-word;
  word-break: break-word;
}

.quick-panel-link-no-wrap {
  white-space: nowrap;
}

.quick-panel-icon {
  color: $brand-dark-grey;
  display: inline-block;
  font-size: 1em;
  margin-left: 3px;
}

$quick-panel-font-size: 12px; /* This effectively controls the zoom level.  Don't use the zoom css: https://stackoverflow.com/a/57693524/491553 */

.quick-panel-outer-container {
  background: $brand-light-grey;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid $brand-mild-grey;
  display: block;
  height: 45px;
  position: fixed;
  width: 65px;
  z-index: 2000;

  //noinspection Stylelint
  top: 150px;
  right: 0;

  .container {
    font-size: $quick-panel-font-size;
  }

  // Change Autocomplete styles in Chrome
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    font-size: $quick-panel-font-size;
  }
}

@media print {
  .quick-panel-outer-container {
    display: none;
  }
}

@media (max-width: 500px) {
  .quick-panel-outer-container {
    display: none;
  }
}

@media (max-height: 500px) {
  .quick-panel-outer-container {
    display: none;
  }
}

.quick-panel-header {
  background-color: $brand-white;
  border: $brand-mild-grey 1px solid;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
  height: 45px;
  display: block;
  padding-left: 7px;
}

.quick-panel-header-text {
  display: inline-block;
  height: 100%;
  padding-top: 5px;
  width: calc(100% - 60px);
}

.quick-panel-header-icon {
  margin-right: 10px;
}

.quick-panel-header-right-icons {
  float: right;
  display: inline-block;
  height: 100%;
  padding-top: 11px;
}

.quick-panel-inner-container,
.quick-panel-static-container {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0;
  padding-right: 0;

  .container {
    display: block;
    width: calc(100% - #{$scrollbar-size});
  }

  .approval-legend {
    width: 70%;
  }

  .title-bar-bottom-border {
    display: block;
  }

  .alert {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

$quick-panel-handle-width: 18px;
.quick-panel-inner-container {
  height: calc(100% - 45px);
  width: calc(100% - #{$quick-panel-handle-width});

  //noinspection Stylelint
  position: absolute;
  left: $quick-panel-handle-width;

  .container {
    width: calc(100% - #{$scrollbar-size});
  }
}

.quick-panel-inner-container .editor-body-container {
  overflow: auto;
}

.quick-panel-static-container {
  background-color: $brand-light-grey;
  height: 100%;
  width: 100%;

  //noinspection Stylelint
  position: absolute;

  .container {
    width: 100%;
  }

  .title-bar {
    margin: 0;
    padding: 15px 15px;
    width: 100%;
  }

  #bodyDiv {
    padding-left: 32px;
    padding-right: 60px;
  }
}

.title-bar-line-1 {
  min-height: 32px;
  padding-bottom: 5px;
}

.title-bar-line-2 {
  min-height: 48px;
  padding-top: 0;
  padding-bottom: 5px;
  flex-flow: row-reverse !important;
}

.quick-panel-left-border {
  display: inline-block;
  position: absolute;
  height: calc(100% - 45px);
  width: 13px;
}

#quickPanelGripDiv {
  color: $brand-medium-grey;
  height: 100%;
  text-align: center;
}

#quickPanelGrip {
  font-size: calculateEm(18px);
  height: 100%;
  width: calculateEm(7px);
}

.quick-panel-help-message {
  margin-top: 30px;

  .quick-panel-icon {
    margin-left: 5px;
    margin-top: -10px;
    margin-right: 5px;
    position: static;
  }
}

.quick-panel-title-bar-container,
.static-panel-title-bar-container {
  margin-left: -30px;
  margin-right: -30px;
  position: sticky; // So that the top title bar always shows

  .title-bar {
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
}

.quick-panel-title-bar-title-container {
  color: $brand-black;
  flex-grow: 1;
  font-weight: bold;

  .title-bar-primary-name {
    font-size: calculateEm(18px);
  }

  .title-bar-version {
    font-family: "Open Sans", sans-serif;
    font-size: calculateEm(14px);
    padding: 0 7px;
    color: $brand-medium-grey;
    font-style: normal;
    font-weight: normal;
  }
}

@media (min-width: 768px) {
  .quick-panel-inner-container .title-bar {
    margin-left: -15px;
    padding-left: 30px;
    padding-right: 20px;
    width: calc(100% + 20px);
  }
}

.quick-panel-title-bar-buttons {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
}

.quick-panel-title-bar-buttons-break {
  margin-bottom: 5px;
  margin-top: 0;
  padding-right: 10px;

  .btn {
    margin-left: 5px;
  }
}

.quick-panel-title-bar-button-div {
  float: left;
}

.quick-panel-title-bar-button {
  border-radius: 20px !important;
  font-size: 1.4em;
  height: 34px;
  margin-bottom: 1px !important;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 0 !important;
  outline: 0 none !important;
  padding: 1px 4px 0 0 !important;
  width: 34px;
}

.quick-panel-title-bar-button-text {
  white-space: nowrap;
}

.document-quickpanel-warning {
  align-items: center;
  display: flex;
  font-size: 0.95rem;
  justify-content: space-between;
  margin-inline: 1.5rem 2.7rem;
}

.qp-doc-editor-link {
  font-weight: 600;
  min-width: fit-content;
}

.quick-panel-title-bar-button-divider {
  border-left: 1px solid #666;
  border-right: 1px solid $hover-color;
  height: 20px;
  margin-left: 3px;
  margin-right: 3px;
}

.quick-panel-blank-state {
  color: $brand-dark-grey;
  font-weight: 600;
  line-height: 25px;
  margin: 8.5rem auto 0;
  text-align: center;
  white-space: pre-wrap;
  width: 45%;
}

.quick-panel-title-bar-button-check-propose,
.quick-panel-title-bar-button-save,
.quick-panel-title-bar-button-reset {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 4px !important;
}

.quick-panel-title-bar-button-switch-edit {
  padding-top: 0 !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.quick-panel-title-bar-button-switch-view {
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.quick-panel-loading-div {
  animation: fadeOut 300ms;
  height: 120px;
  margin: -60px 0 0 -60px;
  width: 240px;
  z-index: 3001;

  //noinspection Stylelint
  position: absolute;
  top: 50%;
  left: 50%;
}

#quickPanelLoadingImg {
  margin-left: calc(50% - 61px);
  margin-bottom: 40px;
}

#quickPanelLoadingMessage {
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}

.quick-panel-loading-dim-wrapper {
  animation: fadeOut 300ms;
  background: $brand-white;
  opacity: 0.5;
  width: 100%;
  z-index: 3000;

  //noinspection Stylelint
  position: absolute;
  top: 0;
  bottom: 0;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

.quick-panel-record-info-box {
  margin-top: 20px;
}

#quickPanelOpenRecord {
  text-decoration: underline;
}

.quick-review-title-bar {
  background-color: $brand-light-grey;
  color: $brand-black;
  padding-bottom: 18px;
  padding-top: 18px;
}

.quick-panel-external-link-rotate {
  margin-top: -5px;
  transform: rotate(-45deg);
}

.quick-panel-title-state {
  color: $brand-dark-grey;
  font-family: old-stamper, serif;
  font-size: calculateEm(30px);
  font-weight: normal;
  padding-right: 10px;
  margin-left: auto;
}

.static-panel-title-bar-container .quick-panel-title-state {
  font-size: calculateEm(14px);
}

.static-panel-icon-link {
  color: $brand-black;
  font-size: calculateEm(16px);
  margin-left: 30px;
}

.quick-panel-tabs {
  margin-left: 0;
}
